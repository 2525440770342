import { useTranslation } from 'react-i18next';
import { type ProgramListItem as ProgramListItemType, type Locale } from '../selectProgram.types';
import { ProgramListItem } from './ProgramListItem';

export function ProgramList({
    items,
    intro,
    preapproved = false,
}: {
    items: ProgramListItemType[];
    intro: Locale | undefined;
    preapproved?: boolean;
}) {
    const { t } = useTranslation('back_royal');

    return (
        <div className="program-list mt-[2rem] px-2">
            {intro && (
                <div className="intro-wrapper mb-[2rem] w-full text-xs font-normal text-grey-shuttle">
                    {t(intro.key, intro.params ?? {})}
                </div>
            )}
            <div className="program-list-items flex flex-wrap justify-center gap-x-[2rem] gap-y-[3rem]">
                {items.map(item => (
                    <ProgramListItem key={item.programType} item={item} preapproved={preapproved} />
                ))}
            </div>
        </div>
    );
}
