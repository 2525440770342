import { setupBrandNameProperties } from 'AppBranding';
import cacheAngularTemplate from 'cacheAngularTemplate';
import angularModule from './settings_module';
import template from '../views/edit_notifications.html';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('editNotifications', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const ngToast = $injector.get('ngToast');
        const TranslationHelper = $injector.get('TranslationHelper');
        const NavigationHelperMixin = $injector.get('Navigation.NavigationHelperMixin');

        return {
            restrict: 'E',
            templateUrl,
            scope: {},
            link(scope) {
                NavigationHelperMixin.onLink(scope);
                setupBrandNameProperties($injector, scope);

                //-------------------------
                // Initialization
                //-------------------------

                // Setup localization keys
                const translationHelper = new TranslationHelper('settings.edit_notifications');

                scope.preferencesProxy = {};

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                Object.defineProperty(scope, 'careerProfile', {
                    get() {
                        return $rootScope.currentUser.career_profile;
                    },
                });

                function getSectionConfig(sectionKey, opts = {}) {
                    const entry = _.extend(
                        {
                            controlTitleTranslationKey: `settings.edit_notifications.${sectionKey}`,
                            notificationPreferences: [
                                {
                                    key: sectionKey,
                                    desc: `${sectionKey}_desc`,
                                    type: 'checkbox',
                                },
                            ], // if the section requires multiple checkboxes, supply an array of the notification properties that belong to the checkbox group
                            requiresConfirmationToToggleOff: false, // controls whether a confirmation dialog modal should pop up with custom messaging when attempting to update the preference
                            requiresConfirmationToToggleOn: false,
                            thingToSave: 'currentUser',
                            showThanks: true, // used in confirm popup
                            get(key) {
                                return scope.currentUser[key];
                            },
                            set(key, val) {
                                scope.currentUser[key] = val;
                            },
                        },
                        opts,
                    );

                    _.forEach(entry.notificationPreferences, preference => {
                        scope.preferencesProxy[preference.key] = entry.get(preference.key);
                    });
                    return entry;
                }

                scope.$watch('currentUser', () => {
                    if (!scope.currentUser) {
                        return;
                    }

                    const newsLetterSectionKey = `notify_email_newsletter${
                        scope.currentUser.isMiyaMiya ? '_miya_miya' : ''
                    }`;
                    scope.notificationConfigs = [
                        getSectionConfig(newsLetterSectionKey, {
                            notificationPreferences: [
                                {
                                    key: 'notify_email_newsletter',
                                    title: newsLetterSectionKey,
                                    desc: `${newsLetterSectionKey}_desc`,
                                    type: 'button-toggle',
                                    inputOptions: [
                                        {
                                            value: true,
                                            label: 'enable',
                                        },
                                        {
                                            value: false,
                                            label: 'disable',
                                        },
                                    ],
                                    translationValues: { brandName: scope.brandNameShort },
                                },
                            ],
                            translationValues: { brandName: scope.brandNameShort },
                        }),
                    ];

                    if (scope.currentUser.messaging_enabled && scope.currentUser.sendbird_access_token) {
                        scope.notificationConfigs = scope.notificationConfigs.concat([
                            getSectionConfig('pref_allow_community_notifications', {
                                notificationPreferences: [
                                    {
                                        key: 'pref_allow_community_notifications',
                                        desc: 'pref_allow_community_notifications_desc',
                                        type: 'checkbox-switch',
                                    },
                                ],
                            }),
                        ]);
                    }
                });
                scope.isSelected = (key, value) => scope.preferencesProxy[key] === value;

                //-------------------------
                // Navigation / Actions
                //-------------------------

                function performUpdate(key) {
                    const notificationConfig = _.find(scope.notificationConfigs, config =>
                        _.includes(_.map(config.notificationPreferences, 'key'), key),
                    );
                    const notificationPreference = notificationConfig.notificationPreferences.find(
                        pref => pref.key === key,
                    );

                    // actually apply the change in the users model
                    // or the career profile model
                    const val = scope.preferencesProxy[key];
                    notificationConfig.set(key, val);

                    // save the model over the api
                    const thingToSave = scope[notificationConfig.thingToSave];
                    thingToSave.save().then(() => {
                        const translatedSectionTitle = translationHelper.get(
                            // Most of the time we can just use the `key`, but the `notificationPreference`
                            // can optionally supply a separate `title` property, which will be used as the
                            // notification preference name in the toast message.
                            notificationPreference.title || notificationPreference.key,
                            notificationConfig.translationValues,
                        );
                        ngToast.create({
                            content: translationHelper.get('update_account_success', {
                                title: translatedSectionTitle,
                            }),
                            className: 'success',
                        });
                    });
                }

                scope.updateAccount = (key, value) => {
                    const notificationConfig = _.find(scope.notificationConfigs, config =>
                        _.includes(_.map(config.notificationPreferences, 'key'), key),
                    );

                    if (!notificationConfig) {
                        throw new Error(`Unrecognized notification key: ${key}`);
                    }

                    // checkbox types use ng-model instead of passing in a value. button-toggle types pass in a value
                    if (angular.isDefined(value)) {
                        scope.preferencesProxy[key] = value;
                    }

                    const val = scope.preferencesProxy[key];

                    // handle special-cases where the notification preference requiresConfirmationToToggleOff
                    const shouldConfirm =
                        (notificationConfig.requiresConfirmationToToggleOff && !val) ||
                        (notificationConfig.requiresConfirmationToToggleOn && val);

                    if (shouldConfirm) {
                        const DialogModal = $injector.get('DialogModal');

                        const options = {
                            showThanks: notificationConfig.showThanks,
                            text: translationHelper.get(`${key}_confirm_${val ? 'on' : 'off'}`),
                            confirmCallback() {
                                performUpdate(key);
                            },
                            cancelCallback() {
                                scope.preferencesProxy[key] = !val; // undo change in proxy
                            },
                        };

                        DialogModal.confirm(options);
                    } else {
                        // otherwise, just apply the change
                        performUpdate(key);
                    }
                };
            },
        };
    },
]);
