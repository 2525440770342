
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_notifications = window.Smartly.locales.modules.en.back_royal.settings.edit_notifications || {};
window.Smartly.locales.modules.en.back_royal.settings.edit_notifications = {...window.Smartly.locales.modules.en.back_royal.settings.edit_notifications, ...{
    "email_notifications": "EMAIL NOTIFICATIONS",
    "update_account_success": "'{{title}}' subscription successfully updated.",
    "notify_email_newsletter": "{{brandName}} Updates",
    "notify_email_newsletter_desc": "I would like to receive updates about new course offerings and occasional news from {{brandName}}. <b>Note:</b> if you have applied to {{brandName}} and opt not to receive {{brandName}} Updates, you will still receive a notification with your admission decision.",
    "notify_email_newsletter_miya_miya": "Miya Miya Updates",
    "notify_email_newsletter_miya_miya_desc": "I would like to receive occasional news updates about Miya Miya.",
    "notify_employer_communication_updates": "Employer Communications",
    "notify_employer_communication_updates_desc": "I would like to receive emails with requests to connect and messages from Employers.",
    "notify_employer_communication_updates_confirm_off": "By opting out of employer communications your profile will be made inactive.",
    "notify_employer_communication_updates_confirm_on": "Enabling employer communications will make your profile visible to employers in the Career Network.",
    "notify_featured_positions_updates": "Career Network Updates",
    "pref_allow_community_notifications": "Community Notifications",
    "pref_allow_community_notifications_desc": "Chat messages from students and alumni",
    "daily": "Daily",
    "weekly": "Weekly",
    "bi_weekly": "Bi-weekly",
    "never": "Never",
    "recommended_positions_info": "You will only receive email when there are new positions that match your profile.",
    "enable": "Enable",
    "disable": "Disable"
}
}
    