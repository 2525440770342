
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.resources = window.Smartly.locales.modules.en.back_royal.resources || {};
window.Smartly.locales.modules.en.back_royal.resources.resources = window.Smartly.locales.modules.en.back_royal.resources.resources || {};
window.Smartly.locales.modules.en.back_royal.resources.resources = {...window.Smartly.locales.modules.en.back_royal.resources.resources, ...{
    "resources_title": "Resources",
    "admissions": "Admissions",
    "academic_and_program_support": "Academic <span class=\"sm:hidden md:inline\">and Program</span> Support",
    "career_services": "Career Services",
    "library_services": "Library Services",
    "student_events": "Student Events",
    "search_for_articles": "Search for Articles",
    "search": "Search",
    "open_search": "Open Search",
    "close_search": "Close Search",
    "category": "Category",
    "select_a_category": "Select Category",
    "read_more": "Read more",
    "in_category": "In category",
    "search_results_found": "Search results for: ",
    "articles_count": "{count} {count, plural, =1 {article} other {articles}}",
    "we_have_found": "We have found <span class=\"font-bold\">{count} {count, plural, =1 {result} other {results}}</span> for this search",
    "search_more_link_quantic": "https://support.quantic.edu/search?query={query}",
    "search_more_link_valar": "https://valar-support.quantic.edu/search?query={query}",
    "search_more_link_execEd": "https://exec-ed-support.quantic.edu/search?query={query}",
    "for_further_search_results": "For further search results, ",
    "click_here": "click&nbsp;here",
    "articles": "Articles",
    "academic_and_program_support_title": "Academic and Program Support",
    "academic_and_program_support_content_one_title": "Academic Support",
    "academic_and_program_support_content_one_description": "The Quantic Academic Advising Team can help you plan your studies, balance work and life obligations, and get back on track when necessary. Learn more about <a href=\"https://support.quantic.edu/article/860-academic-advising\" target=\"_blank\">one-on-one Academic Advising sessions</a>. <br><br><a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Quantic faculty members</a> are available to answer all of your academic questions and to consult on projects, capstones, and other work in progress. Learn more about <a href=\"https://support.quantic.edu/article/861-faculty-office-hours\" target=\"_blank\">faculty office hours</a>.",
    "academic_and_program_support_content_two_title": "Program Support",
    "academic_and_program_support_content_two_description": "Quantic staff are here to support you, including offering help with questions about accessibility and accommodations, billing and tuition reimbursement, and transcripts or other enrollment documents. Each cohort is also assigned a Slack coordinator who can answer questions, facilitate discussions, and make sure that you have the information you need to thrive in the program.",
    "academic_and_program_support_content_two_description_short": "Quantic staff are here to support you, including offering help with questions about accessibility and accommodations, billing and tuition reimbursement, and enrollment documents.",
    "advising": "Advising",
    "advising_description": "The Quantic Academic Advising Team can help you plan your studies, balance work and life obligations, and get back on track when necessary.",
    "faculty": "Faculty",
    "faculty_description": "<a href=\"https://quantic.edu/meet-our-academic-team/\" target=\"_blank\">Quantic faculty members</a> are available to answer all of your academic questions and to consult on projects, capstones, and other work-in-progress. Learn more about <a href=\"https://exec-ed-support.quantic.edu/article/1232-faculty-office-hours\" target=\"_blank\">faculty office hours</a>.",
    "research": "Research",
    "research_description": "When your projects require research, our librarian offers efficient, easy-to-follow guides to help you get started.",
    "can_t_find_what_you_re_looking_for": "Can't find what you're looking for? ",
    "search_here": "Search&nbsp;here",
    "we_have_found_articles": "We have found <span class=\"font-bold\">{count}</span> {count, plural, =1 {article} other {articles}}.",
    "career_services_title": "Career Services",
    "career_services_content_title": "Career Services Offerings",
    "career_services_content_description": "Each of you is here for a transformational experience, whether that includes enhancing your knowledge for a promotion, gaining new skills for a career pivot, developing your audience and platform to become an entrepreneur, or increasing the confidence you have in yourself as a business leader. Wherever you are in your journey, Career Services is here to support you! <br><br> Through a combination of live and asynchronous sessions, in both group and individual formats, we cover career topics from self-assessment to job strategies, interview prep to negotiating job offers. As you navigate school, work, and everyday responsibilities, your goals and timelines may shift, and you can engage with Career Services when the timing is right for you.",
    "career_services_content_description_alternate": "Each of you is here for a transformational experience, and Career Services is here to help ensure you leverage your certificates to grow your network and meet your current and future career goals.<br /><br /> Through a combination of live and recorded group sessions, you will have the opportunity to learn from and engage with your program peers, recruiters, hiring managers, and industry experts. Additionally, check out the articles in this section for tips and additional information.",
    "library_services_title": "Library Services",
    "library_services_content_one_title": "No building? No problem",
    "library_services_content_one_description": "Our modern programs deserve a modern library. Industry-best <a href=\"https://support.quantic.edu/article/952-databases\" target=\"_blank\">research tools</a>, accessible 24/7. Efficient, easy-to-follow <a href=\"https://support.quantic.edu/article/953-guides\" target=\"_blank\">guides</a> to help you get started. Librarian-led webinars and research <a href=\"https://calendly.com/quantic-library/office-hours\" target=\"_blank\">consults</a>. We’ll even teach you how to <a href=\"https://support.quantic.edu/article/954-citations\" target=\"_blank\">cite.</a>",
    "library_services_content_two_title": "More than books",
    "library_services_content_two_description": "You’ll learn not just what to do and how to do it, but why it’s important to you and your audience whether they are customers, clients or investors. <br>You’re going to ❤️ your library.",
    "library_services_content_three_title": "Course Summary Book",
    "library_services_content_three_description": "Within each course, you have access to one or more PDFs providing a succinct summary of what you've learned. We've compiled these summaries into a digital book that you can take with you after graduation.",
    "library_services_links_title": "Database Access",
    "library_services_links_description": "{brandNameShort} provides students full access to several academic databases—no username or password required. Click the links below to access each database; note that these links cannot be bookmarked.",
    "library_services_link_one_label": "Statista",
    "library_services_link_two_label": "One Business",
    "library_services_link_three_label": "IBISWorld",
    "available_app_store": "Available App Store",
    "available_google_play": "Available Google Play",
    "copyright": "© Quantic Holdings, Inc. All rights reserved.",
    "homepage": "Homepage",
    "privacy_policy": "Privacy Policy",
    "terms_and_conditions": "Terms and Conditions",
    "cookies": "Cookies",
    "student_events_title": "Student Events",
    "student_events_content_title": "Student Events and Networking Opportunities",
    "student_events_content_description": "Welcome to Student Events! There are ample opportunities for robust networking and out-of-classroom learning opportunities at Quantic, including in-person conferences, student organizations, case study discussions, and more. <br><br> Want to attend an in-person conference? Start a Student Organization? Attend a special event in your city? Keep an eye out for the invites! We also facilitate academic engagement opportunities, such as live case study discussions, topical webinars, office hours, and more, so you’ll have the opportunity to connect with our academic experts throughout the program.",
    "student_events_content_description_alternate": "Welcome to student events! There are ample opportunities for robust networking and out-of-classroom learning at Quantic.<br /><br />Keep an eye out for invitations to virtual meetups, special events in your city, and academic engagement opportunities, such as live case study discussions, topical webinars, office hours, and more. You’ll have the opportunity to connect with our academic experts, fellow students, and alumni throughout the program.",
    "welcome_message": "Our students have spoken and we've listened! The Quantic app now features a Resources section dedicated to making important program information easily available.<br>Search Resources to find anything from the administrative tools you need to succeed in your program to career resources that you can use beyond graduation.<br><br>Happy studying!",
    "close_welcome_message": "Close welcome message",
    "admissions_title": "Admissions",
    "admissions_content_title": "Admissions",
    "admissions_content_pre_application_description": "<div><p>Our Admissions Team is here to ensure that your application process runs smoothly. We’re here to answer questions about our different programs, our student and alumni network, upcoming Admissions events, tuition and scholarship information, as well as general questions about selection criteria and admission deadlines.<br><br>You can <a href=\"/settings/application\">complete your application</a> now or connect with us at <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a>.</p><br><p>Below you will find resources such as application FAQs, admissions statistics and recent class breakdowns, helpful guides to company tuition benefits, and much more. We can’t wait to meet you!</p></div>",
    "admissions_content_post_application_description": "<div><p>Our Admissions Team is here to ensure that your application process runs smoothly. We’re here to answer questions about our different programs, our student and alumni network, upcoming Admissions events, tuition and scholarship information, as well as general questions about selection criteria and admission deadlines.<br><br>You can connect with us directly at <a href=\"mailto:admissions@quantic.edu\">admissions@quantic.edu</a>.</p><br><p>Below you will find resources such as application FAQs, admissions statistics and recent class breakdowns, helpful guides to company tuition benefits, and much more. We can’t wait to meet you!</p></div>",
    "failed_to_load_articles": "Failed to load articles."
}
}
    