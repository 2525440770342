
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.settings = window.Smartly.locales.modules.en.back_royal.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.settings = window.Smartly.locales.modules.en.back_royal.settings.settings || {};
window.Smartly.locales.modules.en.back_royal.settings.settings = {...window.Smartly.locales.modules.en.back_royal.settings.settings, ...{
    "notifications": "Notifications",
    "preferences": "Preferences",
    "network": "Network",
    "profile": "Profile",
    "sign_out": "Sign Out",
    "faq": "FAQ",
    "feedback": "Give Feedback",
    "switch_program": "Switch Program",
    "billing": "Billing",
    "nominations": "Nominations",
    "account": "Account",
    "transcripts": "Transcripts",
    "documents": "Documents",
    "settings_title": "SETTINGS",
    "menu_title": "MENU",
    "my-profile": "Profile",
    "grades": "Grades",
    "select-program": "Apply to a Program",
    "select-preapproved-program": "Join a Program",
    "application_program_type_mba_emba": "MBA/EMBA Application",
    "application_program_type_msba": "MSBA Application",
    "application_program_type_msse": "MSSE Application",
    "application_program_type_ai_for_technical_leaders_cert": "AI for Technical Leaders Certificate Application",
    "application_program_type_bus_analytics_leaders_cert": "Business Analytics for Leaders Certificate Application",
    "application_program_type_cmo_cert": "CMO Program Certificate Application",
    "application_program_type_cto_cert": "CTO Program Certificate Application",
    "application_program_type_data_science_foundations_cert": "Data Science Foundations Certificate Application",
    "application_program_type_executive_cert": "Executive Program Certificate Application",
    "application_program_type_fin_for_managers_cert": "Finance & Accounting for Managers Certificate Application",
    "application_program_type_founders_cert": "Founders Program Certificate Application",
    "application_program_type_learn_code_gpt_cert": "Learn to Code Using ChatGPT Certificate Application",
    "application_program_type_prototype_gpt_cert": "AI for Building Software Certificate Application",
    "application_program_type_tyb_ai_cert": "Transform Your Business with AI and ChatGPT Certificate Application",
    "application_program_type_tyb_blockchain_cert": "Transform Your Business with Blockchain Certificate Application",
    "change_application_program_type": "<a ng-click=\"onClickChangeApplicationProgramType()\">Change Program</a>",

    "application_status": "Application Status",
    "program_status": "Program Status",
    "apply_to_a_program": "Apply to a Program",
    "edit_application": "Edit Application",
    "deferred_application_status_x": "Deferred for {{programTitle}}",
    "congratulations_you_are_in": "Congratulations, you're in!",
    "you_are_now_registered": "You are now registered!",
    "now_secure_place_in_x": "Now join your classmates by securing your place in the <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> class.",
    "now_secure_place_in_x_no_guide": "Now join your classmates by securing your place in the {{cohortTitle}} class.",
    "register_or_review_exec_ed": "Register now for {{cohortTitle}}.<br /> Or review all your eligible <a ng-click=\"openCongratsModal()\">Executive Education certificates</a>.",
    "you_have_secured_enrollment_in_x": "You have secured your enrollment in the <a ng-click=\"launchProgramGuide()\">{{cohortTitle}}</a> class.<br class=\"hidden-xs\"> If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "you_have_secured_enrollment_in_x_no_guide": "You have secured your enrollment in the {{cohortTitle}} class.<br>If you have any questions, please email us at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a>.",
    "registration_opens_on": "Registration opens on <strong>{{registrationOpen}}</strong>.",
    "class_begins_on_x": "Class begins on {{startDate}}",
    "graduation_requirements_not_met": "Graduation requirements not met",
    "registration_incomplete": "Your registration is incomplete.",
    "follow_steps_to_secure": "Please follow the steps below to secure your place in the {{cohortTitle}}.",
    "application_deadline_reminder": "The upcoming deadline to apply is {{deadline}}.",

    "mba": "MBA",
    "application": "Apply to {{brandName}}",
    "mba_documentation": "Documentation",

    "team_header": "Team Profile",
    "candidates_header": "Candidates Preferences",
    "preview_header": "Preview Your Profile",

    "company_sub_header": "Complete your company profile",
    "team_sub_header": "Tell candidates a little more about the team you're hiring for",
    "you_sub_header": "Complete your hiring manager profile",
    "candidates_sub_header": "This will help us deliver better candidates to you",
    "preview_sub_header": "Candidates will see this when you like their profiles",

    "your_account_is_locked": "Your Account is Locked",
    "please_contact_the_bursar": "Our records show your account is missing one or more required payments. Please contact the Bursar by email at <a href=\"mailto:{{brandEmail}}\">{{brandEmail}}</a> to resolve this issue.",
    "affordable_tuition_modal": "Thanks to technology and funding innovations, tuition is a fraction of traditional degrees at just ${{standardMonthlyPayment}} USD per month over the course of the program."
}
}
    