import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { type ProgramListWithSubSections as ProgramListWithSubSectionsType } from '../selectProgram.types';
import { ProgramList } from './ProgramList';

export function ProgramListWithSubSections({ programList }: { programList: ProgramListWithSubSectionsType }) {
    const { t } = useTranslation('back_royal');
    const [selectedSubSectionIndex, setSelectedSubSectionIndex] = useState<number>(0);

    const selectedSubSection = programList.subSections()[selectedSubSectionIndex];

    return (
        <div className="program-list-with-sub-sections px-[8px]">
            <div className="sub-section-tabs mb-9 flex justify-center gap-x-2 gap-y-5 sm:gap-x-5">
                {programList.subSections().map((subSection, index) => (
                    <button
                        type="button"
                        key={subSection.title.key}
                        onClick={() => setSelectedSubSectionIndex(index)}
                        className={`sub-section-tab rounded border-none p-2 text-xxs font-semibold ${
                            selectedSubSectionIndex === index
                                ? 'selected bg-blue text-white'
                                : 'bg-transparent text-blue'
                        }`}
                    >
                        {t(subSection.title.key, subSection.title.params ?? {}).toLocaleUpperCase()}
                    </button>
                ))}
            </div>
            <ProgramList items={selectedSubSection.items()} intro={selectedSubSection.intro} />
        </div>
    );
}
